<template>
  <div id="NyugtaModal" class="scrollable-modal">
    <modal
      height="auto"
      name="NyugtaModal"
      width="600px"
      @before-open="beforeModalOpen"
    >
      <!--<h5 class="text-center bottom-border-separator">Nyitvatartási szabály</h5>-->
      <div class="modal-content">

        <embed :src="url" width="580px" height="700px" />

      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      url: '',
    };
  }, //data    
  methods: {
    beforeModalOpen (event) {
        this.url = this.$store.state.url.getNyugta + '/' + event.params.nyugta.hashToken+'?sendfile=1';
    }
  }

}
</script>