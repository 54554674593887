<template>
	<div>
		<div class="row expanded collapse">
			<div class="medium-1 columns">
				<left-menu />
			</div>
			<!-- <section> -->
			<div class="medium-11 columns main-content-container" style="
          height: 100vh;
          overflow: hidden;
          display: flex;
          flex-direction: column;
        ">
				<div class="row expanded">
					<div class="medium-3 columns">
						<h3>Nyugták</h3>
					</div>
					<div class="medium-6 columns my-tools">
						<date-picker v-model="dateFrom" :calendar-button="true" :monday-first="true"
							calendar-button-icon="fi-calendar" format="yyyy. MM. dd." language="hu" />
						<div style="flex:0 0 auto; padding:0 .5em;">-tól</div>
						<date-picker v-model="dateTo" :calendar-button="true" :monday-first="true"
							calendar-button-icon="fi-calendar" format="yyyy. MM. dd." language="hu" />
						<div style="flex:0 0 auto; padding:0 .5em;">-ig</div>
					</div>
					<div class="medium-3 columns my-tools">
						<button class="button" @click="getNyugtaLista">Lekérdezés</button>
						<a class="button" :href="nyugtaListaHref" target="_blank"><i class="fi-page-export-csv" /> Export </a>
					</div>
				</div>
				<div class="row expanded" style="flex: 1; overflow-y: auto">
					<div class="medium-12 columns tableBlock">
						<table class="hover">
							<thead>
								<tr>
									<th></th>
									<th>Bizonylatszám</th>
									<th>Dátum</th>
									<th>Összeg</th>
									<th>Vendég</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="nyugta in nyugtaListaFetchedData.nyugtaLista">
									<td>
										<a title="Szabály módosítása" @click="openModal(nyugta)"><i
												class="fi-zoom-in" /></a>
									</td>
									<td>{{ nyugta.bizonylatszam }}</td>
									<td>{{ getFormattedDate(nyugta.datum) }}</td>
									<td class="text-right">{{ nyugta.amount }}</td>
									<td>{{ nyugta.user }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<!-- </section> -->
		</div>
		<nyugta-modal />
	</div>
</template>

<script>
import moment from "moment";
import LeftMenu from "../../left_menu/LeftMenu";
import DatePicker from "vuejs-datepicker";
import { getCookie } from "../../../processors/cookie";
import errorHandle from "@/processors/errorHandle";
import { getFormattedDate } from "../../../processors/util";
import NyugtaModal from './NyugtaModal.vue';

export default {
	components: {
		LeftMenu,
		DatePicker,
		NyugtaModal,
	},
	data() {
		return {
			dateFrom: moment().format("YYYY-MM-DD"),
			dateTo: moment().format("YYYY-MM-DD"),
			nyugtaListaFetchedData: {},
		};
	},
	methods: {
		getNyugtaLista() {
			this.$http({
				url: this.$store.state.url.nyugtaLista,
				method: "GET",
				params: {
					userID: getCookie("userID"),
					token: getCookie("token"),
					date_from: moment(this.dateFrom).format("YYYY-MM-DD"),
					date_to: moment(this.dateTo).format("YYYY-MM-DD"),
				},
			}).then(
				(response) => {
					this.nyugtaListaFetchedData = response.body;
					console.log("--- nyugta lista table fetched: ");
					console.log(response.body);
				},
				(error) => {
					errorHandle.httpError(error);
				}
			);
		},
		getFormattedDate: getFormattedDate,
		openModal(nyugta) {
			this.$modal.show('NyugtaModal', { nyugta });
		},
	},
	computed: {
		nyugtaListaHref() {

			return this.$store.state.url.getNyugtaListXlsx
				+ '?date_from=' + moment(this.dateFrom).format("YYYY-MM-DD")
				+ '&date_to=' + moment(this.dateTo).format("YYYY-MM-DD")
				+ '&client=' + this.$store.state.client.domain
				+ '&userID=' + getCookie('userID')
				+ '&token=' + getCookie('token')

				;

		},
	}
};
</script>

<style scoped>
.my-tools {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.my-tools .button {
	margin: 0 4px 0 0;
	white-space: nowrap;
}

.xxxmain-content-container {
	margin-top: 1rem;
	max-height: 100vh;
}
</style>
